
import {defineComponent, ref} from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "sizes",
  components: {
    CodeHighlighter,
  },
  setup() {
    const sizes = ref(["30px", "50px", "75px", "100px", "125px", "150px"]);

    const symbolSizes = ref([
      "20px",
      "25px",
      "30px",
      "35px",
      "40px",
      "45px",
      "50px",
      "55px",
      "60px",
      "65px",
      "70px",
      "75px",
      "100px",
      "125px",
      "150px",
      "175px",
      "200px",
    ]);

    return {
      sizes,
      symbolSizes,
    };
  },
});
